import { createTheme, useSvelteUITheme } from "@svelteuidev/core";

export const theme = {
	...useSvelteUITheme(),
	...createTheme({
		fonts: {
			standard: "praxis-next-condensed",
		},
		radii: {
			xs: "0",
			sm: "0",
			md: "0",
			lg: "0",
			xl: "0",
		},
	})
};

export enum FormState {
	Loading = "loading",
	Ready = "ready",
	Success = "success",
	Error = "error",
}
